.login, .reset_password, .reset_password_confirm {
    width: 100%;

    @include media-breakpoint-up(sm) {
        max-width: 600px;
    }

    .card {
        width: 100%;
    }
}
