.was-validated {
    #inputBGWUploadContainer {
        :invalid ~ .form-control.file-name {
            @extend .is-invalid;
        }

        :valid ~ .form-control.file-name {
            @extend .is-valid;
        }
    }
}

tr.table-deleted {
    text-decoration: line-through;
}

[data-pui-page="invoices_wizard#step_pkz_bgw"] {
    .alert-link {
        .fa-chevron-down {
            rotate: 0deg;
            transition: transform .35s ease-in-out;

        }

        &.collapsed .fa-chevron-down {
            transform:rotate(-90deg);
        }
    }
}
